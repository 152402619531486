// extracted by mini-css-extract-plugin
export var align_center = "paragraph-module--align_center--geI+D";
export var align_left = "paragraph-module--align_left--rLvyn";
export var align_right = "paragraph-module--align_right--JECBN";
export var paragraph = "paragraph-module--paragraph--Bxote";
export var size_l = "paragraph-module--size_l--SdzDj";
export var size_m = "paragraph-module--size_m--Y+1p5";
export var size_s = "paragraph-module--size_s--URS1j";
export var size_xl = "paragraph-module--size_xl--nNzSM";
export var size_xs = "paragraph-module--size_xs--MbEu9";
export var size_xxl = "paragraph-module--size_xxl--xIyw9";
export var size_xxxl = "paragraph-module--size_xxxl--XdeMM";
export var spacing_0 = "paragraph-module--spacing_0--r0Gc0";
export var spacing_l = "paragraph-module--spacing_l--qCqUz";
export var spacing_m = "paragraph-module--spacing_m--xaDs1";
export var spacing_s = "paragraph-module--spacing_s--u2ZWO";
export var spacing_xl = "paragraph-module--spacing_xl--D09iK";
export var spacing_xxl = "paragraph-module--spacing_xxl--magRs";
export var spacing_xxxl = "paragraph-module--spacing_xxxl--NMBBs";
export var uppercase = "paragraph-module--uppercase--ZLfyQ";
export var weight_400 = "paragraph-module--weight_400--lCJ0u";
export var weight_700 = "paragraph-module--weight_700--qToTe";
export var weight_900 = "paragraph-module--weight_900--Gqhh2";