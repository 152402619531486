import * as React from "react";
import Layout from "../components/03_organisms/Layout/Layout";
import Patch from "../components/03_organisms/Patch/Patch";
import Seo from "../components/03_organisms/Seo/Seo";
import SlideShow from "../components/03_organisms/SlideShow/SlideShow";

function IndexPage() {
    return (
        <Layout>
            <Seo title="Designer" />
            <Patch />
            <SlideShow />
        </Layout>
    );
}

export default IndexPage;
