import React from "react";
import Logo from "../../01_atoms/Logo/Logo";
import { Paragraph } from "../../01_atoms/Paragraph/Paragraph";
import * as style from "./patch.module.scss";

function Patch() {
    return (
        <section className={style.patch}>
            <div>
                <Logo />
            </div>
            <div className={style.content}>
                <Paragraph size="L" spacing="0">
                    Big brands, big design.
                </Paragraph>
                <Paragraph size="L" spacing="0">
                    →{" "}
                    <a
                        href="mailto:hendrik.everaerts@designer.be?subject=Website contact"
                        className={style.contact_link}
                    >
                        contact us
                    </a>
                </Paragraph>
            </div>
        </section>
    );
}

export default Patch;
