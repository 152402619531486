import * as React from "react";
import { Helmet } from "react-helmet";

function Seo({ title }) {
    return (
        <Helmet title={title}>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
        </Helmet>
    );
}

export default Seo;
