import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useTransition, animated, config } from "react-spring";
import * as style from "./slideshow.module.scss";

function SlideShow({ delay }) {
    const campaignData = useStaticQuery(graphql`
        {
            allFile(
                filter: { relativeDirectory: { eq: "campaigns" } }
                sort: { fields: [base] }
            ) {
                edges {
                    node {
                        name
                        childImageSharp {
                            gatsbyImageData(
                                width: 1200
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                quality: 95
                            )
                        }
                    }
                }
            }
        }
    `);

    const campaigns = campaignData.allFile.edges;
    const [index, set] = useState(0);
    const transitions = useTransition(campaigns[index], {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    console.log(campaigns);

    useEffect(() => {
        setInterval(() => {
            set(state => (state + 1) % campaigns.length);
        }, delay || 5000);
    }, [delay, campaigns.length]);

    return (
        <div className={style.container}>
            {transitions((styleProps, iamge) => {
                return (
                    <animated.div
                        className={style.slide}
                        key={styleProps.key}
                        style={{ ...styleProps }}
                    >
                        <GatsbyImage
                            objectFit="cover"
                            alt={iamge.node.name}
                            image={getImage(
                                iamge.node.childImageSharp.gatsbyImageData
                            )}
                        />
                    </animated.div>
                );
            })}
        </div>
    );
}

export default SlideShow;
