import React from "react";
import * as style from "./paragraph.module.scss";
import cx from "classnames";

export const Paragraph = ({
    children,
    font,
    size = "m",
    align = "left",
    spacing = "default",
    weight = "400",
    theme = "light",
    uppercase = false,
    className,
}) => {
    // define classes
    const classname = cx(
        style.paragraph,
        uppercase && style[`uppercase`],
        font && style[`font_${font}`],
        size && style[`size_${size.toLowerCase()}`],
        align && style[`align_${align.toLowerCase()}`],
        spacing && style[`spacing_${spacing.toLowerCase()}`],
        theme && style[`theme_${theme.toLowerCase()}`],
        weight && style[`weight_${weight.toLowerCase()}`],
        className
    );

    // render html
    return <p className={classname}>{children}</p>;
};
